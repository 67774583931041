import React from "react";
import { Helmet } from "react-helmet";

export default function Admin() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin</title>
        <meta name="description" content="Admin" />
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <iframe
        src={"https://" + "admin." + window.location.host}
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "scroll",
        }}
      />
    </>
  );
}
